import React from "react";

import Header from "../components/Header";
import Tiles from "../components/Tiles";
import Footer from "../components/Footer";

function Catalog() {
  return (
    <div id="b1" style={{
      backgroundImage: `url(${process.env.PUBLIC_URL + `/images/box1.jpg`})`
    }}>
      <Header />
      <Tiles />
      <Footer />

    </div>
  );
}

export default Catalog;
