import React from "react";

const Tiles = () => {
  return (
    <div id="tiles">
      <img src={process.env.PUBLIC_URL + "/images/c1-2.jpg"} alt="" />
      <img src={process.env.PUBLIC_URL + "/images/c2.jpg"} alt="" />
      <img src={process.env.PUBLIC_URL + "/images/c3.jpg"} alt="" />
      <img src={process.env.PUBLIC_URL + "/images/c4.jpg"} alt="" />
      <img src={process.env.PUBLIC_URL + "/images/c5.jpg"} alt="" />
      <img src={process.env.PUBLIC_URL + "/images/c6.jpg"} alt="" />
      <img src={process.env.PUBLIC_URL + "/images/c7.jpg"} alt="" />
      <img src={process.env.PUBLIC_URL + "/images/c8.jpg"} alt="" />
      <img src={process.env.PUBLIC_URL + "/images/c9.jpg"} alt="" />
      <img src={process.env.PUBLIC_URL + "/images/c10.jpg"} alt="" />
      <img src={process.env.PUBLIC_URL + "/images/c11.jpg"} alt="" />
      <img src={process.env.PUBLIC_URL + "/images/c12.jpg"} alt="" />
      <img src={process.env.PUBLIC_URL + "/images/c13.jpg"} alt="" />
      <img src={process.env.PUBLIC_URL + "/images/c14.jpg"} alt="" />
      <img src={process.env.PUBLIC_URL + "/images/c15.jpg"} alt="" />
      <img src={process.env.PUBLIC_URL + "/images/c16.jpg"} alt="" />
      <img src={process.env.PUBLIC_URL + "/images/c17.jpg"} alt="" />
      <img src={process.env.PUBLIC_URL + "/images/c18.jpg"} alt="" />
      <img src={process.env.PUBLIC_URL + "/images/c19.jpg"} alt="" />
      <img src={process.env.PUBLIC_URL + "/images/c20.jpg"} alt="" />
      <img src={process.env.PUBLIC_URL + "/images/c21.jpg"} alt="" />
      <img src={process.env.PUBLIC_URL + "/images/c23.jpg"} alt="" />
      <img src={process.env.PUBLIC_URL + "/images/c24.jpg"} alt="" />
      <img src={process.env.PUBLIC_URL + "/images/c25.jpg"} alt="" />
      <img src={process.env.PUBLIC_URL + "/images/c26.jpg"} alt="" />
      <img src={process.env.PUBLIC_URL + "/images/c27.jpg"} alt="" />
      <img src={process.env.PUBLIC_URL + "/images/c28.jpg"} alt="" />
      <img src={process.env.PUBLIC_URL + "/images/c29.jpg"} alt="" />
      <img src={process.env.PUBLIC_URL + "/images/c30.jpg"} alt="" />
      <img src={process.env.PUBLIC_URL + "/images/c31.jpg"} alt="" />
      <img src={process.env.PUBLIC_URL + "/images/c32.jpg"} alt="" />
      <img src={process.env.PUBLIC_URL + "/images/c33.jpg"} alt="" />
    </div>
  );
};

export default Tiles;
