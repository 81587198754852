import React from "react";

import Catalog from "./pages/Catalog";
import Index from "./pages/Index";
import Contact from "./pages/Contact";
import About from "./pages/About";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {
  return (
    <div>
      <Router>
        <Switch>
          <Route path="/about" exact>
            <About />
          </Route>
          <Route path="/catalog" exact>
            <Catalog />
          </Route>
          <Route path="/contact" exact>
            <Contact />
          </Route>
          <Route path="/" exact>
            <Index />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
