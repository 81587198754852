import React from "react";

import { NavLink } from "react-router-dom";

const Header = () => {
  return (
    <div className="page">
      <header>
        <div className="headerCon">
          <div className="thelogo">
            <NavLink to="/">
              <img
                id="logoPic"
                src={process.env.PUBLIC_URL + "/images/cnsLogo.png"}
                alt=""
              />
            </NavLink>
          </div>
          {/*Normal Menu*/}
          <div className="normal-menu">
            <ul>
              <li><NavLink className="normal-navs" activeClassName="onPage" to="/about">About</NavLink></li>
              <li><NavLink className="normal-navs" activeClassName="onPage" to="/catalog">Lup sup</NavLink></li>
              <li><NavLink className="normal-navs"  activeClassName="onPage" to="/contact">Contact</NavLink></li>
              </ul>
          </div>
        </div>
      </header>
    {/*Hamburger Menu*/}
      <div id="nav-container">
        <div className="bg"></div>
        <div className="button" tabindex="0">
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
        </div>
        <div id="nav-content" tabindex="0">
          <ul>
          <li><NavLink className="navs" activeClassName="onPage" to="/about">About</NavLink></li>
          <li><NavLink className="navs" activeClassName="onPage" to="/catalog">Lup sup</NavLink></li>
          <li><NavLink className="navs" activeClassName="onPage" to="/contact">Contact</NavLink></li>
          </ul>
        </div>
      </div>
    
    </div>
  );
};

export default Header;
