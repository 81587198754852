import React from "react";

import Header from "../components/Header";
import Footer from "../components/Footer";

const About = () => {
  return (
    <div id="b1" style={{
      backgroundImage: `url(${process.env.PUBLIC_URL + `/images/bkg2.jpg`})`
    }}>
      <Header />

      <div id="desc">
        <div id="vidz">
          <iframe
            src="https://www.youtube.com/embed/TyK_4WOYNwM"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="charles"
          ></iframe>
        </div>

        <div id="abt">
          <p>
            Chainsandshackles is a vintage clothing shop. Charles owns the
            business and does mostly everything but some friends help him.
          </p>
        </div>

        <div id="gl">
          <img
            className="ck"
            src={process.env.PUBLIC_URL + "/images/ck.gif"}
            alt=""
          />
          <img
            className="cherub"
            src={process.env.PUBLIC_URL + "/images/cherub.gif"}
            alt=""
          />
          <img
            className="disc"
            src={process.env.PUBLIC_URL + "/images/cd.gif"}
            alt=""
          />
          <img
            className="fl"
            src={process.env.PUBLIC_URL + "/images/gl.gif"}
            alt=""
          />
          <img
            className="disc"
            src={process.env.PUBLIC_URL + "/images/cd.gif"}
            alt=""
          />
          <img
            className="cherub"
            src={process.env.PUBLIC_URL + "/images/cherub.gif"}
            alt=""
          />
          <img
            className="ck"
            src={process.env.PUBLIC_URL + "/images/ck.gif"}
            alt=""
          />
        </div>
        <div> pick your favourite shuai ge </div>
        <div id="idiots">
          <a href="https://www.instagram.com/houlengzai">
            <img src={process.env.PUBLIC_URL + "/images/me.png"} alt="" />
          </a>
          <a href="https://www.instagram.com/escalatorjude/">
            <img src={process.env.PUBLIC_URL + "/images/jude.png"} alt="" />
          </a>
          <a href="https://www.instagram.com/ngshihan/">
            <img src={process.env.PUBLIC_URL + "/images/sham.png"} alt="" />
          </a>
          <a href="https://www.instagram.com/eugine.tan/">
            <img src={process.env.PUBLIC_URL + "/images/eu.png"} alt="" />
          </a>
          <a href="https://www.instagram.com/a_nesttt/">
            <img src={process.env.PUBLIC_URL + "/images/er.png"} alt="" />
          </a>
          <a href="https://www.instagram.com/itsalvinnnn/">
            <img src={process.env.PUBLIC_URL + "/images/al.png"} alt="" />
          </a>
        </div>
      </div>

      <Footer />
    
    </div>
  );
};

export default About;
