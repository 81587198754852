import React from "react";

import Header from "../components/Header";
import Footer from "../components/Footer";


const Contact = () => {
  return (
    <div id="b1" style={{
      backgroundImage: `url(${process.env.PUBLIC_URL + `/images/clouds.jpg`})`
    }}>
      <Header />

      <div id="cheung">
        <div className="smoke">
          <img
            src="https://media1.tenor.com/images/559a2029a0e8b1811126676b9c2dfa00/tenor.gif"
            alt=""
          />
        </div>


        <div className="commissions">
          <p>
            My name is Timothy but you can call me tonight ;) anything else just
            ask <a href="https://www.instagram.com/chainsandshackles/">Charles</a>
            <br />
            <br />
            This website is intended to be a catalog and moodboard for Chainsandshackles.
            <br />
            If you like what you see, I'm open to commissions -{" "}
            <a href="https://www.linkedin.com/in/timothy-mah-1a35531a5/">
              help a starving artist
            </a>
          </p>
        </div>

        <div className="bounce-con">
          <img
            className="bounce"
            src={process.env.PUBLIC_URL + "/images/card.jpg"}
            alt=""
          />
        </div>

        <img
            className="bounce2"
            src={process.env.PUBLIC_URL + "/images/dragon1.gif"}
            alt=""
          />

        <div className="sayo-row">
          <img
            id="sayo"
            src={process.env.PUBLIC_URL + "/images/sayo.png"}
            alt=""
          />
          <img
            className="psyloke"
            src="https://media.giphy.com/media/npsdMZJt3InzW/giphy.gif"
            alt=""
          />
        </div>
      </div>

      <Footer />

    </div>
  );
};

export default Contact;
