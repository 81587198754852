import React from "react";

// import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div id="btmBar">
      <div className="stickers-con">
        <div id="stickers">
          <img src="https://anlucas.neocities.org/mcaffee.gif" alt="" />
          <img
            src="https://anlucas.neocities.org/html_learn_it_today.gif"
            alt=""
          />
          <img
            src="https://anlucas.neocities.org/made_with_windows.gif"
            alt=""
          />
          <img src="https://anlucas.neocities.org/yahoomail.gif" alt="" />
          <img src="https://anlucas.neocities.org/javanow.gif" alt="" />
          <img src="https://anlucas.neocities.org/hatemac.jpg" alt="" />
          <img src="https://anlucas.neocities.org/leave.gif" alt="" />
          <img src="https://anlucas.neocities.org/Ebay.gif" alt="" />
          <img src="https://anlucas.neocities.org/mars_icon.gif" alt="" />
          <img src="https://anlucas.neocities.org/Winamp.gif" alt="" />
          <img src="https://anlucas.neocities.org/get.gif" alt="" />
          <img src="https://anlucas.neocities.org/linux_powered.gif" alt="" />
          <img src="https://anlucas.neocities.org/icongallery.gif" alt="" />
          <img src="https://anlucas.neocities.org/nonags.gif" alt="" />
          <img src="https://anlucas.neocities.org/javass.gif" alt="" />
        </div>
      </div>

      <div id="creds">
        <p>
          This website is just for fun please don't sue me
          <br />
          <br />
        </p>
      </div>
    </div>
  );
};

export default Footer;
