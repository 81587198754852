import React from "react";

import { Link } from "react-router-dom";

const Index = () => {
  return (
        <div id="b0">
          <div className="b0-container">
            <div id="unicorns">
              <div id="unicornSlider">
                <img src={process.env.PUBLIC_URL + "/images/unicorn.gif"} alt="" />
                <img src={process.env.PUBLIC_URL + "/images/unicorn.gif"} alt="" />
                <img src={process.env.PUBLIC_URL + "/images/unicorn.gif"} alt="" />
                <img src={process.env.PUBLIC_URL + "/images/unicorn.gif"} alt="" />
              </div>
            </div>
            <div id="win">
              <Link to="/catalog">
                <img src={process.env.PUBLIC_URL + "/images/coverWin.png"} alt="" />
              </Link>
            </div>
            <div id="shazams">
              <img src={process.env.PUBLIC_URL + "images/lightning.gif"} alt="" />
              <img src={process.env.PUBLIC_URL + "images/lightning.gif"} alt="" />
            </div>
          </div>
      </div>
  );
};

export default Index;
